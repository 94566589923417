<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Alterar dados
    </v-card-title>

    <v-card-text>
      <v-card-text>
        <v-text-field
          v-model="data.description"
          label="Nome do Processo"
          outlined
          dense
        />

        <v-text-field
          v-model="data.self_id"
          label="Self ID"
          outlined
          dense
        />

        <v-switch
          v-model="isActive"
          label="Ativo"
          color="success"
          class="ml-2 mt-2"
          inset
        />

        <div class="d-flex justify-end mt-2">
          <v-btn
            color="error"
            @click="closeModal()"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="info"
            class="ml-5"
            @click="sendModalData()"
          >
            <span
              v-if="!isLoadingSendData"
            >Enviar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'

export default {
  mixins: [formatters],

  props: {
    data: {
      type: [Object, String],
      required: true,
    },
  },

  data() {
    return {
      isActive: Boolean,
      isLoadingSendData: false,
    }
  },

  computed: {
    verifyStatus() {
      return this.data?.status === 'ATIVO'
    },
  },

  created() {
    this.isActive = this.verifyStatus
  },

  methods: {
    async sendModalData() {
      this.isLoadingSendData = true

      const { data, isActive } = this

      const body = {
        description: data.description.toUpperCase(),
        self_id: data.self_id,
        status: isActive ? 'ATIVO' : 'INATIVO',
      }

      await axiosIns.put(`api/v1/sgq/process/update/${data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso',
            text: 'Dados alterados!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error.message,
          })
        })
        .finally(() => {
          this.isLoadingSendData = false
          this.closeModal()
        })
    },
  },
}
</script>
