<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="procedureName"
          label="Nome do Processo"
          :rules="[rules.required]"
          outlined
          dense
        />

        <v-text-field
          v-model="selfId"
          label="Self ID"
          outlined
          dense
        />

        <v-switch
          v-model="isActive"
          label="Ativo"
          color="success"
          class="ml-2 mt-2"
          inset
        />
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoadingSendData"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      selfId: '',
      procedureName: '',

      isActive: false,
      isLoadingSendData: false,
    }
  },

  methods: {
    async sendModalData() {
      const formIsValid = this.$refs.form.validate()
      const { procedureName, selfId, isActive } = this

      const body = {
        description: procedureName.toUpperCase(),
        self_id: selfId,
        status: isActive ? 'ATIVO' : 'INATIVO',
      }

      if (!formIsValid) {
        return
      }

      this.isLoadingSendData = true

      await axiosIns.post('/api/v1/sgq/process/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error.message,
          })
        })
        .finally(() => {
          this.isLoadingSendData = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.selfId = ''
      this.procedureName = ''
      this.isActive = false
    },
  },
}
</script>
