<template>
  <div>
    <v-card
      class="mb-6"
    >
      <v-card-title class="pt-0 pb-0">
        <span>
          Processos
        </span>

        <v-spacer />

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-7 mr-4"
          label="Buscar por Descrição"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingTableData"
        loading-text="Carregando dados..."
      >
        <template
          v-slot:no-data
        >
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.edit="{item}">
          <v-icon
            medium
            class="me-2"
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{item}">
          <v-icon
            medium
            class="me-2"
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ !item.created_at ? '' : dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showProcessModal"
      width="700px"
    >
      <ProcessesModal
        @updatedTable="updatedTable()"
        @close="showProcessModal = false"
      ></ProcessesModal>
    </v-dialog>

    <v-dialog
      v-model="showProcessDetails"
      width="700px"
    >
      <ProcessesDetails
        :key="processDetails.id"
        :data="processDetails"
        @updatedTable="updatedTable()"
        @close="showProcessDetails = false"
      ></ProcessesDetails>
    </v-dialog>
  </div>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import ProcessesDetails from './ProcessesDetails.vue'
import ProcessesModal from './ProcessesModal.vue'

export default {
  components: {
    ProcessesModal,
    ProcessesDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      processDetails: {},
      endpointDelete: '/api/v1/sgq/process/destroy',

      headers: [
        {
          text: 'DESCRIÇÃO', value: 'description', sortable: false, align: 'left',
        },
        {
          text: 'SELF ID', value: 'self_id', sortable: false, align: 'center',
        },
        {
          text: 'STATUS', value: 'status', sortable: false, align: 'center',
        },
        {
          text: 'CRIADO EM', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'DELETAR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],
      isLoadingTableData: false,

      showProcessModal: false,
      showProcessDetails: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoadingTableData = true

      await axiosIns.get('api/v1/sgq/process/index').then(
        response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        },
      ).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoadingTableData = false
      })
    },

    findFilterResult() {
      const { filterData, itemsTable } = this
      const filter = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        ({
          description,
        }) => description.toLowerCase().includes(filter),
      )
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => {
          this.showProcessModal = true
        },
        details: () => {
          this.showProcessDetails = true
        },
      }

      if (type in modal) {
        modal[type]()
        this.processDetails = data

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>
