import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-2"},[_vm._v(" Cadastrar ")]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":"Nome do Processo","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.procedureName),callback:function ($$v) {_vm.procedureName=$$v},expression:"procedureName"}}),_c(VTextField,{attrs:{"label":"Self ID","outlined":"","dense":""},model:{value:(_vm.selfId),callback:function ($$v) {_vm.selfId=$$v},expression:"selfId"}}),_c(VSwitch,{staticClass:"ml-2 mt-2",attrs:{"label":"Ativo","color":"success","inset":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoadingSendData)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }