import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-2"},[_vm._v(" Alterar dados ")]),_c(VCardText,[_c(VCardText,[_c(VTextField,{attrs:{"label":"Nome do Processo","outlined":"","dense":""},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c(VTextField,{attrs:{"label":"Self ID","outlined":"","dense":""},model:{value:(_vm.data.self_id),callback:function ($$v) {_vm.$set(_vm.data, "self_id", $$v)},expression:"data.self_id"}}),_c(VSwitch,{staticClass:"ml-2 mt-2",attrs:{"label":"Ativo","color":"success","inset":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoadingSendData)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }